<template>
  <section class="pt-10 pb-20 lg:pb-56">
    <div class="container__content space-y-6 lg:space-y-10">
      <Heading :text="homepage.thought.heading" heading-class="text-[2rem]" />
      <p class="relative z-10 text-sm lg:text-xl text-grey-light lg:leading-[1.8]">{{ homepage.thought.text }}</p>
      <UtilityQuotation
        class="absolute -bottom-36 lg:-bottom-40 right-0 lg:-right-96 z-0 w-40 lg:w-full"
        :color="parishConfig.colors.secondary.dark"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
const homepage = useHomepage()
const parishConfig = useParishConfig()
const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})
</script>
