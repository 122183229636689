<template>
  <svg :width="width" :height="height" viewBox="0 0 389 298" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M389 220.155C389 243.671 380.896 262.727 364.688 277.322C349.29 291.107 329.84 298 306.337 298C276.352 298 252.85 288.675 235.831 270.025C218.812 250.563 210.303 225.02 210.303 193.396C210.303 160.961 215.166 133.391 224.891 110.686C235.426 87.9809 247.582 69.3306 261.359 54.7347C275.947 39.3279 290.94 27.57 306.337 19.4612C321.735 10.5415 335.107 4.05442 346.453 0L385.353 65.6816C369.145 72.1687 355.368 82.7102 344.022 97.3061C332.676 111.091 326.193 126.093 324.572 142.31C340.78 142.31 355.368 149.608 368.334 164.204C382.111 177.989 389 196.639 389 220.155ZM178.697 220.155C178.697 243.671 170.593 262.727 154.384 277.322C138.986 291.107 119.536 298 96.0344 298C66.049 298 42.5469 288.675 25.5281 270.025C8.50938 250.563 0 225.02 0 193.396C0 160.961 4.8625 133.391 14.5875 110.686C25.1229 87.9809 37.2792 69.3306 51.0562 54.7347C65.6437 39.3279 80.6365 27.57 96.0344 19.4612C111.432 10.5415 124.804 4.05442 136.15 0L175.05 65.6816C158.842 72.1687 145.065 82.7102 133.719 97.3061C122.373 111.091 115.89 126.093 114.269 142.31C130.477 142.31 145.065 149.608 158.031 164.204C171.808 177.989 178.697 196.639 178.697 220.155Z"
      :fill="`url(#paint0_linear_310_${randomNumber1})`"
      fill-opacity="0.4"
    />
    <defs>
      <linearGradient :id="`paint0_linear_310_${randomNumber1}`" x1="142" y1="23.5" x2="223.918" y2="256.866" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" stop-opacity="0.3" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script setup lang="ts">
defineProps({
  width: {
    type: String,
    default: '389',
  },
  height: {
    type: String,
    default: '298',
  },
  color: {
    type: String,
    default: '#000000',
  },
})

const randomNumber1 = computed(() => Math.round(Math.random() * 10000))
</script>
